<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-row>
      <b-col
        lg="3"
        md="6"
        sm="12"
      >
        <b-form-group>
          <label for="cCompany">Filter by Company</label>
          <b-form-select
            id="cCompany"
            v-model="fields.cCompany"
            :options="cCompanies"
            type="text"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Please select Company --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="12"
      >
        <b-form-group>
          <label for="organization">Filter by Organization</label>
          <b-form-select
            id="organization"
            v-model="fields.organization"
            :options="cOrganization"
            type="text"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Please select Organization --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="12"
      >
        <b-form-group>
          <label for="from_time">From</label>
          <b-input
            id="from_time"
            v-model="fields.from_time"
            type="date"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="12"
      >
        <b-form-group>
          <label for="to_time">To</label>
          <b-input
            id="to_time"
            v-model="fields.to_time"
            type="date"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <VueApexCharts
          :options="options"
          :series="series"
          width="100%"
          height="400px"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="4"
        md="6"
        sm="12"
      >
        <b-form-group>
          <label for="company">Filter by Company</label>
          <b-form-select
            id="company"
            v-model="fields.company"
            :options="cCompany"
            type="text"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Please select Company --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col
        lg="4"
        md="6"
        sm="12"
      >
        <b-form-group>
          <label for="cfrom_time">From</label>
          <b-input
            id="cfrom_time"
            v-model="fields.cfrom_time"
            type="date"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="4"
        md="6"
        sm="12"
      >
        <b-form-group>
          <label for="cto_time">To</label>
          <b-input
            id="cto_time"
            v-model="fields.cto_time"
            type="date"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12">
        <VueApexCharts
          :options="options2"
          :series="series2"
          width="100%"
          height="400px"
        />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import VueApexCharts from 'vue-apexcharts'
import { AdminDashboardService, SharedListService } from '@/services'
export default {
  name: 'AdminDashboard',

  middleware: ['auth', 'admin'],
  components: {
    VueApexCharts
  },
  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      fields: {
        from_time: '',
        to_time: '',
        cfrom_time: '',
        cto_time: '',
        organization: null,
        company: null,
        cCompany: null
      },
      cCompanies: [],
      companies: [],
      organizations: [],
      series: [],
      options: {
        labels: [],
        chartOptions: {
          labels: []
        },
        chart: {
          type: 'donut'
        },

        plotOptions: {
          pie: {
            customScale: 0.8,
            size: 200
          }
        }
      },
      series2: [],
      options2: {
        labels: [],
        chartOptions: {
          labels: []
        },
        chart: {
          type: 'donut'
        },

        plotOptions: {
          pie: {
            customScale: 0.8,
            size: 200
          }
        }
      }
    }
  },
  computed: {
    cOrganization () {
      return [].concat(this.organizations)
    },
    cCompany () {
      return [].concat(this.companies)
    }
  },
  watch: {
    'fields.organization' () {
      this.getDataPerItem()
    },
    'fields.from_time' () {
      this.getDataPerItem()
    },
    'fields.to_time' () {
      this.getDataPerItem()
    },
    'fields.company' () {
      this.getTotalBookedPerUser()
    },
    'fields.cfrom_time' () {
      this.getTotalBookedPerUser()
    },
    'fields.cto_time' () {
      this.getTotalBookedPerUser()
    },
    'fields.cCompany' (value) {
      this.getOrganizationList(value)
    }
  },

  mounted () {
    core.index()
    this.getDataPerItem()
    this.getTotalBookedPerUser()
    this.getCompanyList()
  },
  methods: {
    async getOrganizationList (companyId) {
      await SharedListService.getOrganizationList(companyId).then(({ data }) => {
        this.organizations = data.map(({ id, organization_name }) => {
          return {
            value: id,
            text: organization_name
          }
        })
      })
    },
    async getCompanyList () {
      await SharedListService.getCompanyList().then(({ data }) => {
        const newCompanies = data.map(({ id, company_name }) => {
          return {
            value: id,
            text: company_name
          }
        })
        this.companies = newCompanies
        this.cCompanies = newCompanies
      })
    },
    async getDataPerItem () {
      await AdminDashboardService.getOrganizationPerItems(
        `organization_id=${this.fields.organization}&date_from=${this.fields.from_time}&date_to=${this.fields.to_time}`
      ).then(({ data }) => {
        const { series, labels } = data
        this.series = series
        this.updateXAxis(labels)
      })
    },
    updateXAxis (labels) {
      this.options = {
        ...this.options,
        labels: labels,
        chartOptions: {
          labels: labels
        }
      }
    },
    async getTotalBookedPerUser () {
      await AdminDashboardService.getTotalBookedPerUser(
        `company_id=${this.fields.company}&date_from=${this.fields.cfrom_time}&date_to=${this.fields.cto_time}`
      ).then(({ data }) => {
        const { series, labels } = data
        this.series2 = series
        this.updateXAxis2(labels)
      })
    },
    updateXAxis2 (labels) {
      this.options2 = {
        ...this.options2,
        labels: labels,
        chartOptions: {
          labels: labels
        }
      }
    }
  }
}
</script>
